import { FC } from 'react';

import { PortalError } from '@/admin/components/PortalError';
import { Button } from '@/common/components/Button';
import { Center } from '@/common/components/Display/Center';
import { CloseIcon } from '@/common/components/Icons/CloseIcon';
import { RefreshIcon } from '@/common/components/Icons/RefreshIcon';
import { EmptyStateResultProps } from '@/common/components/Result/EmptyStateResult';
import { Text } from '@/common/components/Typography/Text';

export interface PortalErrorDisplayProps
  extends Pick<EmptyStateResultProps, 'title' | 'subTitle'> {
  /**
   * A plain-english description of what the error boundary is wrapping.
   * This will be used as the prefilled subject line in the support email link.
   */
  area?: string;

  onCancelled?: () => void;
  onReload?: () => void;
}

export const PortalErrorDisplay: FC<PortalErrorDisplayProps> = ({
  title = 'Oops!',
  subTitle = 'There has been an unexpected error.',
  area = 'Unexpected error',
  onCancelled,
  onReload
}) => {
  return (
    <PortalError
      title={title}
      subTitle={
        <>
          <Text inherit>{subTitle}</Text>
          <Text inherit>
            If the issue keeps happening, contact{' '}
            <a href={`mailto:support@komo.tech?subject=${area}`}>
              support@komo.tech
            </a>
            .
          </Text>
        </>
      }
    >
      <Center>
        {onCancelled && (
          <Button
            mt="0.5rem"
            variant={!onReload ? 'filled' : 'default'}
            leftSection={<CloseIcon />}
            onClick={onCancelled}
          >
            Close
          </Button>
        )}
        {onReload && (
          <Button
            mt="0.5rem"
            variant="filled"
            onClick={onReload}
            leftSection={<RefreshIcon />}
          >
            Reload
          </Button>
        )}
      </Center>
    </PortalError>
  );
};
