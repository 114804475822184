'use client';

import { ThemeProvider } from '_theme';
import { useEffect } from 'react';

import { PortalErrorDisplay } from '@/admin/components/shared/PortalErrorBoundary/PortalErrorDisplay';

export default function Error({
  error,
  reset
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    console.error(error);
  }, [error]);

  return (
    <ThemeProvider>
      <PortalErrorDisplay onReload={location.reload} />;
    </ThemeProvider>
  );
}
